<ng-container
  [ngTemplateOutlet]="loading ? spinner : previewContent">
</ng-container>

<ng-template #spinner>
  <div class="flex flex-col justify-center items-center w-full h-full min-w-full min-h-full">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>

<ng-template #previewContent>
  <ng-container *ngIf="isPDF">
    <ngx-extended-pdf-viewer class="pdf-viewer"
                             [src]="pdfObject"
                             theme="light"
                             height="70vh"
                             isEvalSupported="false"
                             (pdfLoadingFailed)="errorOccurred($event)"
                             (pdfLoaded)="hideLoader()"
                             [useBrowserLocale]="true"
                             [language]="currentLocale"
                             [showOpenFileButton]="false"
                             [showDownloadButton]="false"
                             [showUnverifiedSignatures]="true"
                             [showHandToolButton]="true"
                             [showRotateButton]="true"
                             [showPropertiesButton]="false"
                             [showFindPageRange]="false"
                             [showFindFuzzySearch]="false">
    </ngx-extended-pdf-viewer>
  </ng-container>

  <ng-container *ngIf="isImage" [hidden]="loading">
    <img (load)="hideLoader()" class="img-preview" [src]="imageSrc"/>
  </ng-container>

  <ng-container *ngIf="isText && !loading">
    <pre class="text-content">{{textContent}}</pre>
  </ng-container>
</ng-template>
